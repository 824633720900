h2{
  color:#01245B;
  margin: 0px;
  font-size: 45px;
  font-weight: 700;
  line-height: 1.5em;
}
h3{
  color:#01245B;
  margin: 0px;
  font-size: 23px;
  font-weight: 700;
}
.contact-info-col a {
  font-size: 15px;
  text-decoration: none;
  color: white;
}
.top-bar {
  background: #861212;
  padding: 3px 0px;
}
.contact-info-col i {
  font-size: 12px;
  color: white;
  padding-right: 3px;
}
p{
  margin:0 !important ;
}
.welcome-text p{
  font-size: 16px;
    font-weight: 600;
    color: white;
    font-style: italic;
}
.social-icons-col{
  text-align: right;
}
.social-icons-col i{
  color: white;
  margin-left: 10px;
}
.logo-col img{
  width: 90px !important;
}
.navbar-nav .nav-link {
  font-size: 15px;
  font-weight:700;
  color: #343434;
}
.navbar-nav a.nav-item.nav-link.active {
  color: #861212;
}
.main-menu-sec {
  box-shadow: 0px 3px 10px #d3d3d352;
}
.sub-header {
  padding: 40px 0px;
  text-align: center;
  background-image: linear-gradient(164deg, #1E2B3A 33%, #111820 63%);
}
.sub-header h1{
  color: white;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 800;
}
a.theme-btn {
  background: #861212;
  color: white;
  font-weight: 700;
  padding: 11px 30px;
  text-decoration: none;
  font-style: italic;
  border-radius: 5px;
  border: 2px solid #861212;
}
a.theme-btn:hover, .event-btn:hover {
  color: #861212;
  background: transparent;
  border: 2px solid #861212;
}

.top-heading span {
  font-weight: 700;
  font-size: 13px;
  color: #861212;
}
.top-heading {
  background: #8080803d;
  display: flex;
  border: 3px solid #8080806b;
  border-radius: 7px;
  padding: 8px 10px;
  width: max-content;
  align-items: center;
}
.top-heading img {
  width: 15px !important;
  object-fit: cover;
  height: 15px !important;
  margin-right: 5px;
}
.about-us-section {
  padding: 70px 0px;
  overflow: hidden;
}
.about-us-section p{
  font-style: italic;
}
.img-col img{
  box-shadow: 10px 10px 0px #861212;
  width: 546px;
  height: 362px;
}
li.nav-item {
  margin: 0px 6px;
}
.history-sec{
  background-image: linear-gradient(to bottom,rgb(0 0 0 / 80%),rgb(0 0 0 / 75%)),url('C:\Users\talha\OneDrive\Desktop\Maverickscc\maverickscc\public/gallery/2015/2015-16.jpg');
  background-size: cover;
  color: white;
}
.history-sec .row{
    height: 450px;
}
.history-sec  p{
  width: 68%;
}
.history-sec h2{
  color: white;
}

.upcoming-matches{
  padding: 70px 0px;
  text-align: center;
}
.upcoming-match-box img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.upcoming-match-box {
  padding: 30px;
  border: 3px solid white;
  border-radius: 12px;
  box-shadow: 0px 0px 30px -8px lightgrey;
}
.upcoming-match-box:nth-child(2) {
  background: #212529 !important;
}
.upcoming-match-box:hover, .center-col .upcoming-match-box:hover {
  border-color: gray;
}
p.location-text {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}
p.date-text {
  font-weight: 700;
  font-size: 14px;
}
.center-col .upcoming-match-box{
  background: #E3EAF8;
  border-color: #E3EAF8;
}
.custom-slider img{
  height: 600px;
  width: 100%;
  object-fit: cover;
}
.carousel-caption {
  top: 20%;
  bottom: 0 !important;
  width: 45%;
  background: #0000006b;
  border-radius: 8px;
  height: max-content;
  padding: 30px;
  text-align: left !important;
}
.top-heading {
  background: #ffffffd1;
}
.carousel-caption h2.text-light {
  line-height: 1.2em;
}
.carousel-caption:after {
  position: absolute;
  background: transparent;
  content: "";
  width: 50px;
  height: 50px;
  border: 6px solid #871515;
  right: -10px;
  bottom: -10px;
  border-left: 0px;
  border-top: 0;
  border-bottom-right-radius: 8px;
}
.carousel-caption:before {
  position: absolute;
  background: transparent;
  content: "";
  width: 50px;
  height: 50px;
  border: 6px solid #871515;
  left: -10px;
  top: -10px;
  border-right: 0px;
  border-bottom: 0;
  border-top-left-radius: 8px;
}
.event-content p {
  margin-bottom: 30px !important;
}
.event-content {
  background: #01245B;
  padding: 70px 30px;
  color: white;
}
.event-content h2{
  color: white;
}
.event-content p{
  font-style: italic;
}
.event-section .event-col {
  padding: 50px 0px !important;
  background: #01245B;
}
.event-card .card{
  width: 100%  !important;
}
.event-card img{
  width: 100% !important;
}
.event-section {
  background: #80808014;
}
h5.card-title {
  font-size: 16px;
  font-weight: 700;
}
p.card-text {
  font-size: 14px;
  margin-bottom: 10px !important;
}
.event-btn {
  background: #861212;
  color: white;
  font-weight: 600;
  padding: 5px 10px;
  text-decoration: none;
  font-style: italic;
  border-radius: 5px;
  border: 2px solid #861212;
  font-size: 12px;
}
.card-body {
  padding: 20px;
}
.players-img{
  width: 100% !important;
    height: 250px;
    object-fit: cover;
}
.series-box {
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #d3d3d38a;
  position: relative;
}
.series-box:hover {
  box-shadow: 0px 10px 22px 3px #ebeffdcc;
}
.series-box h3 {
  font-weight: 700;
  font-size: 25px;
}
.series-box strong {
  color: #861212;
}
.series-box span{
  position: absolute;
    font-weight: 800;
    font-size: 40px;
    -webkit-text-stroke: 2px #01245b3b;
    color: transparent;
    bottom: 10px;
    right: 20px;
}
.series-box:hover span{
    -webkit-text-stroke: 2px #861212;
}
.series-box a{
  text-decoration: none;
  color:black;
}
.series-box:hover a{
  color: black;
}
.gallery-main-box{
  position: relative;
}
.gallery-main-box img{
  width: 360px;
  height: 236px;
}
.gallery-main-box h4 {
  color: white;
  font-weight: 700;
  background-image: linear-gradient(90deg, #680000E0 55%, #F2295B00 100%);
  position: absolute;
  bottom: 10px;
  width: 50%;
    padding: 10px;
    transition: 0.5s all ease;  
    text-align: center;
}
.gallery-main-box:hover h4{
  width: 100%;
}
footer img{
  width:100px;
  border-radius: 50%;
}
.footer-links {
  padding: 0px;
  list-style: none;
}
.footer-links li {
  margin-bottom: 10px;
  font-size: 14px;
  /* font-weight: 500; */
}
footer h3{
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 2px solid;
    line-height: 2em;
    display: inline-block;
    margin-bottom: 30px;
}
footer p{
  font-size: 14px;
  margin: 15px 0px !important;
}
footer .footer-links i{
  color: #861212;
}
.footer-social-links a {
  margin-right: 10px;
  background: #861212;
  padding: 5px 8px;
  border-radius: 3px;
}
.footer-social-links a i{
 color: white;
}
.copyright {
  border-top: 1px solid #80808040;
}
footer {
  padding: 30px 0px 0px 0px;
  background: #80808014;
  border:1px solid #80808040;
}
.footer-links a {
  color: #212529;
  text-decoration: none;
}
.footer-links a:hover {
  color:#861212;
  ;
}
.coming-soon-sec {
  padding: 70px 0px;
  text-align: center;
}
h2.coming-soon-text {
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
}
.form-btn{
  background: #861212;
    color: white;
    font-weight: 700;
    padding: 11px 30px;
    text-decoration: none;
    font-style: italic;
    border-radius: 5px;
    border: 2px solid #861212;
}
.nav-link:hover{
  color: #861212 !important;
}
.result-sec img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px #d3d3d3db;
}
.result-sec .result-col{
  padding: 30px;
    border: 3px solid white;
    border-radius: 12px;
    box-shadow: 0px 0px 30px -8px lightgrey;
}
.result-col h4 {
  font-size: 17px;
  font-weight: 800;
}
.result-col .league-text {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 15px !important;
}
.result-col .score-text {
  font-weight: 700;
  font-size: 29px;
  margin-top: 6px !important;
  margin: 0px;
}
.result-col .over-text {
  font-size: 12px;
  font-weight: 700;
}
.result-col .win-text {
  font-size: 17px;
  margin-top: 20px;
  font-weight: 800;
  color: #861212;
  font-style: italic;
}
strong.vs {
  font-size: 20px;
  font-weight: 700;
}
.team-name{
  font-size: 11px;
}
.gallery-imgs {
    display: flex;
    /* flex-grow: 1; */
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
}

.gallery-imgs img {
    width:300px;
    height: auto;
    object-fit:cover;
}
.gallery-imgs{
  padding: 80px 0px;
}
.contact-section p {
  width: 88%;
}
.year-box {
  box-shadow: 0px 0px 50px -6px lightgray;
  border-radius: 8px;
  padding: 20px;
  border: 2px solid white;
}
.year-box:hover {
  border: 2px solid #871414;
}
.year-box h2 {
  font-size: 26px;
  font-weight: 800;
}
.year-box img {
  width: 40px;
  object-fit: cover;
  margin-bottom: 20px;
}
.teams-box img {
  width: 70px;
  object-fit: cover;
}
.teams-box {
  display: flex;
  justify-content: space-around;
}
.date-box {
  padding: 20px 0px !important;
  text-align: center;
  background: #01245B;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.teams-box img {
  width: 70px;
  object-fit: cover;
  border-radius: 50%;
  height: 70px;
}
span.vs {
  font-size: 29px;
  font-weight: 700;
}
.schedule-box {
  box-shadow: 0px 0px 52px 20px #d3d3d34f;
  border-radius: 8px;
}
.date-box p {
  font-size: 12px;
  font-weight: 500;
  color: white;
}
.date-box h4 {
  font-size: 50px;
  color: white;
  font-weight: 800;
  margin: 0;
  line-height: 1.4em;
}
.schedule-box .location{
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
}
.match-detail-box h5, .inning-detail-box h5{
  margin: 0px;
  padding: 10px;
  background: #861212;
  color: white;
  font-size: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.match-detail-box th, .inning-detail-box th{
  font-size: 13px;
}
.match-detail-box, .inning-detail-box {
  border-radius: 5px;
  box-shadow: 0px 0px 30px 2px #d3d3d3d1;
  padding: 0px !important;
}
.match-detail-box h3{
  font-weight: 700;
  font-size: 18px;
}
.inning-detail-box table td {
    font-size: 13px;
}
.total-overs{
  margin-left: 10px;
  font-size: 12px;
  font-weight: 700;
}
.run-rate{
  font-size: 14px !important;
  color: green;
}
.inning-1-head, .inning-2-head, .inning-1-extras{
  background-color:#01245B !important;
}
.totl-head span{
  font-size: 14px !important;
}
.totl-head{
  font-size: 15px !important;
}
a.match-info-link {
  font-size: 13px;
  color: black;
  font-weight: 500;
  margin-top: 8px;
}
.result-col a{
  text-decoration: none;
}
.inning-detail-box img{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.player-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 7px;
  box-shadow: 0px 0px 40px -10px lightgray;
  border: 3px solid #01245b;
}
ul.player-detail-list {
  list-style: none;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  color: #01245b;
}
ul.player-detail-list span {
  font-weight: 500;
  margin-left: 10px;
  font-size: 13px;
  color: #676767;
}
.player-detail-row {
  padding: 15px;
  box-shadow: 0px 0px 40px -7px #d3d3d39c;
  border-radius: 10px;
}
.player-detail-row h3 {
  background: #861212;
  font-size: 17px;
  color: white;
  border-radius: 7px;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}
.inning-detail-box a{
  text-decoration: none;
  color: black;
}
.spec-row img {
  width: 30px;
}
.spec-row h4 {
  font-size: 18px;
  font-weight: 700;
}
.spec-row .col-lg-6 {
  /* border: 1px solid gray; */
  padding: 16px;
}
.blog-content-box img{
  border-radius: 8px;
  height: 400px;
  object-fit: cover;
  margin-bottom: 10px;
}
.match-info-link:hover{
  color: black;
}
.fullmatch-subheader img{
  width:100px;
  object-fit: cover;
  height: 100px;
  border-radius: 50%;
}
.first-team{
  background: #861212;
    padding: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
}
.second-team{
  background: #01245b;
    padding: 15px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
}
p.teamname {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}
p.totalscore {
  font-size: 25px;
  font-weight: 800;
  letter-spacing: 1px;
}
p.totalover {
  font-size: 12px;
}
h3.nxt-match-text.py-3 {
  font-size: 20px;
  margin: 0;
  font-weight: 700;
}
.up-p{
  width: 40%;
  margin:auto !important;
}

@media(max-width:767px){
  .desk-result{
    display:none;
  }
  .mob-result{
    display: block !important;
  }
  .sponsor-slider{
    display: none;
  }
  .sponsor-slider.mobile-sponsor-slider{
    display: block !important;
  }
  .up-p{
    width: 90%;
  }
  .about-us-section {
      text-align: center;
      padding: 30px 0px;
  }
  .top-heading {
    margin: auto;
  }
  .img-col {
    margin-top: 30px;
    padding: 20px;
  }
  .img-col img {
    width: 100%;
    height: auto;
  }
  .history-sec .row {
    height: auto;
    padding: 30px 0px;
  }
  h2 {
    font-size: 30px;
    line-height: 1.2em;
  }
  .history-sec p {
    width: 100%;
  }
  .upcoming-matches{
    padding: 30px 0px;
  }
  .event-content {
    text-align: center;
    padding: 0px 20px;
  }
  .carousel-caption{
    width: 70%;
    text-align: center !important;
  }
  .carousel-caption h2.text-light {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
}
.players-img {
  height: 170px;
}
.player-card{
  min-height: 280px;
}
.result-col .score-text {
  font-size: 14px;
}
.result-col .over-text {
  font-size: 11px;
}
.result-sec img {
  width: 55px;
  height: 55px;
}
.team-name {
  font-size: 8px;
  margin-bottom: 8px !important;
  font-weight: 700;
}


.result-sec .result-col {
  padding: 30px 15px;
}
.result-sec{
  padding: 10px;
}
.contact-section p {
  width: 100%;
}
.navbar-collapse{
  position: absolute;
  background: white;
  top: 103px;
  z-index: 2;
  width: 100%;
  box-shadow: 0px 10px 14px -28px lightgray;
  border-top: 2px solid #871414;
}
ul.nav{
  display: block;
}
.dropdown-menu{
    border: 0px !important;
}
.series-box span {
  font-size: 30px;
}
.p-row{
  padding-left: 10px;
}
.s-box{
  padding: 0px 50px;
}
.match-detail-box h5, .inning-detail-box h5 {
  font-size: 13px;
}
.inning-detail-box table td {
  font-size: 10px;
}
.inning-detail-box img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.inning-detail-box table td{
    padding: 5px !important;
}
table th{
  font-size: 12px !important;
}
.player-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.player-main-row{
  padding: 30px 20px;
}
.fullmatch-subheader img{
  width: 42px;
  height: 42px;
}
.fullmatch-subheader{
  padding: 0px 10px;
}
.first-team{
    padding: 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.second-team{
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

p.teamname {
    font-size: 10px;
}
p.totalscore {
  font-size: 18px;
}
}


/* css for bootstrap navbar dropdown */

@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}

@media(max-width:1700px) and (min-width:1440px){
 .carousel-caption{
  width: 35%;
 }
}

.mob-result{
  display: none;
}
.result-carousel-sec{
  overflow: hidden;
  margin: auto;
  width: 80%;
  padding-top: 30px;
}
.result-carousel-box table{
  width: 90%;
  margin: 10px auto;
}
.result-carousel-box  a{
  text-decoration: none;
}
.result-carousel-score{
  text-align: right;
}
img.result-car-img {
  border-radius: 50%;
  box-shadow: 0px 0px 10px lightgray;
  object-fit: contain;
  width: 50px;
  height: 50px;
  display: inline-block !important;
}
.result-rr {
  background: #01245b;
  color: white;
  padding: 6px 0px;
  font-weight: 600;
  font-style: italic;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.result-carousel-box {
  box-shadow: 0px 0px 20px lightgray;
  border: 2px solid #01245b;
  border-radius: 10px;
}
.match-info-link p i{
  color:#871515;
}
.result-carousel-box:hover{
  background: #01245b;
}
.result-carousel-box:hover a{
  color: white;
}
.result-carousel-box:hover img{
  box-shadow: none;
}
.sponsor-slider img {
  width: 150px;
  height: 100px;
  object-fit: contain;
}
.sponsor-section {
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 80px 0px 30px 0px;
}
.sponsor-section h3 {
 margin-bottom: 30px;
}
.mobile-sponsor-slider{
  display: none;
}